import { useState } from "react";

import { ExportOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CardImg = (props) => {
  const { img, subTitle, title, bigTitle, content, link, linkText, shareIcon } = props;

  const [iconColor, setIconColor] = useState("#0a1e41")
  const { t } = useTranslation();

  return (
    <>
      <div className="flex md:flex-row lg:flex-col flex-col relative pt-6 md:gap-x-5">
        <div className="flex-1">
          <span className="uppercase text-xs text-gray-500 mb-1 md:relative lg:absolute absolute top-0 left-0">
            {subTitle}
          </span>

          <a href={link} className="hover:underline hover:decoration-2 hover:text-black">
            <h3
              className={`${bigTitle ? "lg:text-3xl md:text-2xl text-xl" : "lg:text-xl md:text-base text-base"
                } lg:font-semibold md:font-medium leading-7 mt-5`}
            >
              {t(title)}
            </h3>
          </a>

          <p className="mt-3 lg:text-sm text-xs text-gray-600">{t(content)}</p>
        </div>

        <a
          href={link}
          className={`md:order-2 lg:order-first order-first flex-1 relative parent-option`}
        >
          <img
            src={img}
            alt={title}
            className="w-full h-auto object-cover aspect-video"
          />
          <div className="absolute right-0 bottom-0 bg-sky-500 px-5 py-5 text-sm font-semibold transition-opacity opacity-0 child"
            onMouseEnter={() => {
              setIconColor('#fff')
            }}
            onMouseLeave={() => {
              setIconColor("#0a1e41")
            }}
          >
            {linkText}
            {
              shareIcon && <ExportOutlined
                className="ml-2"
                style={{
                  color: iconColor
                }}
              />
            }
          </div>
        </a>
      </div>
    </>
  );
};

export default CardImg;
