import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./GetInTouchForm.module.scss";

const { TextArea } = Input;

const GetInTouchForm = (props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      window.history.back(); 
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className={`mx-auto w-full p-8 lg:p-6 ${styles["GetInTouchForm"]}`}>
        <div className={`text-right lg:my-5 ${styles["GetInTouchForm-header"]}`}>
          <CloseOutlined
            className="cursor-pointer"
            onClick={handleClose}
            style={{ fontSize: "1.4rem" }}
          />
        </div>

        <div className={`flex lg:flex-row flex-col lg:px-14 md:px-8 py-12 ${styles["GetInTouchForm-body"]}`} style={{ backgroundColor: "var(--color-secondary-light-2)" }}>
          <div className="lg:w-5/12 lg:pr-14 lg:mr-10 lg:mb-0 mb-16">
            <h2 className="font-lab-antiqua md:text-7xl text-5xl">
              {t('learnMoreabout')}
            </h2>
          </div>
          <div className="flex-1">
            <Form
              name="formName"
              layout="vertical"
              size="large"
              initialValues={{ isAgree: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={`${styles["GetInTouchForm-form-row"]}`}>
                <Form.Item
                  label={t('firstName')}
                  name="firstName"
                  rules={[{ required: true, message: t('nameRequired') }]}
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input placeholder={t('firstName')} />
                </Form.Item>
                <Form.Item
                  label={t('lastName')}
                  name="lastName"
                  // rules={[{ required: true, message: t('nameRequired') }]}
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input placeholder={t('lastName')} />
                </Form.Item>
              </div>

              <div className={`${styles["GetInTouchForm-form-row"]}`}>
                <Form.Item
                  label={t('email')}
                  name="email"
                  rules={[{ required: true, message: t('emailRequired') }]}
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input placeholder="yourname@gmail.com" />
                </Form.Item>
                <Form.Item
                  label={t('company')}
                  name="company"
                  // rules={[{ required: true, message: t('companyRequired') }]}
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input placeholder={t('company')} />
                </Form.Item>
              </div>

              <Form.Item
                label={t('jobTitle')}
                name="jobTitle"
                // rules={[{ required: true, message: t('jobTitleRequired') }]}
                className="flex-1 border-b-2 border-gray-300"
              >
                <Input placeholder={t('jobTitle')} />
              </Form.Item>

              <Form.Item label={t('comments')} name="comments">
                <TextArea rows={6} placeholder={t('comments')} />
              </Form.Item>

              <div className={`${styles["GetInTouchForm-form-row"]} justify-between`}>
                <Form.Item name="isAgree" valuePropName="checked">
                  <Checkbox className={`GetInTouchForm-form-row-checkbox lg:text-base text-sm`}>
                    {t('optIn')}
                  </Checkbox>
                </Form.Item>

                <Form.Item className="text-right">
                  <button className="more-btn btn-hover bg-sky-950 text-white">
                    {t('submit')}
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouchForm;