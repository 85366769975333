import Img01 from "@/assets/img/image01.jpg";
import CardImg from "@/components/card/card-img/CardImg";
import newsList from '@/pages/newsroom/news.json';
import image from "@/assets/img/guide/image.png";
import image2 from "@/assets/img/guide/image2.png";
import image3 from "@/assets/img/guide/image3.png";
import news2 from "@/assets/img/guide/news2.png";
import news3 from "@/assets/img/guide/DSC07158.jpg";
import { useTranslation } from "react-i18next";
const NewsModule = (prop) => {
  const { headText, bigItemTitle, rightLink, rightLinkText, isProject=false,contentText } = prop;
  const { t } = useTranslation();

  // const newsList = [
  //   {
  //     img: Img01,
  //     title:
  //       "TCC Breaks Ground on 1.35M-SF Industrial Park in Southwest Houston",
  //     content: "August 26, 2024",
  //     link: "/newsroom/tcc-breaks-ground-on-1-35m-sf-industrial-park-in-southwest-houston",
  //   },
  //   {
  //     img: Img01,
  //     title:
  //       "TCC Breaks Ground On New Operational Training Center for Puget Sound Energy",
  //     content: "August 15, 2024",
  //     link: "/newsroom/tcc-breaks-ground-on-new-operational-training-center-for-puget-sound-energy",
  //   },
  //   {
  //     img: Img01,
  //     title:
  //       "High Street Residential Sells Matson Mill Multifamily Community Outside Philadelphia",
  //     content: "August 9, 2024",
  //     link: "/newsroom/high-street-residential-sells-matson-mill-multifamily-community-outside-philadelphia",
  //   },
  // ];
  const imageMap = {
    "image": image,
    "image2": image2,
    "image3": image3,
    "news2": news2,
    "news3":news3
  };
  
  const projectList = [
    {
      img: Img01,
      title:
        "TCC Breaks Ground on 1.35M-SF Industrial Park in Southwest Houston111",
      content: "August 26, 2024",
      link: "/newsroom/tcc-breaks-ground-on-1-35m-sf-industrial-park-in-southwest-houston",
    },
    {
      img: Img01,
      title:
        "TCC Breaks Ground On New Operational Training Center for Puget Sound Energy",
      content: "August 15, 2024",
      link: "/newsroom/tcc-breaks-ground-on-new-operational-training-center-for-puget-sound-energy",
    },
    {
      img: Img01,
      title:
        "High Street Residential Sells Matson Mill Multifamily Community Outside Philadelphia",
      content: "August 9, 2024",
      link: "/newsroom/high-street-residential-sells-matson-mill-multifamily-community-outside-philadelphia",
    },
  ];


  return (
    <>
      <div className="relative lg:pb-6 pb-16">
        <div className="flex justify-between items-center lg:mb-12 mb-8">
          {headText && (
            <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950">
              {headText}
            </h2>
          )}
        </div>
          
          <div className="lg:mb-12 mb-8" style={{width:'60%'}}>{contentText && <div style={{color:'#474443',lineHeight:"1.5rem"}}>{contentText}</div> }</div>
        <ul className="flex lg:gap-y-10 gap-y-7 flex-wrap pb-8">
          {(isProject ? projectList : newsList).map((item, index) => (
            <li key={index} className="lg:w-1/3 w-full">
              <div className="lg:pr-16">
                <CardImg
                  img={!isProject ? imageMap[item.imgUrl] : item.img}
                  subTitle={item.subTitle}
                  title={t(item.title)}
                  bigTitle={bigItemTitle}
                  content={item.content}
                  link={`/newsroom/${item.id}`}
                  linkText={item.linkText ? t(item.linkText) : t('learnMore')}
                />
              </div>
            </li>
          ))}
        </ul>

        {rightLinkText && (
          <div className="absolute bottom-0 right-0 mb-4 mr-4">
            <a
              href={rightLink}
              className="more-btn btn-hover bg-sky-950 text-white scale-110"
            >
              {rightLinkText}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default NewsModule;
