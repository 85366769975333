import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import { useTranslation } from "react-i18next";
import { Collapse, Form, Input, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Img01 from "@/assets/img/image01.jpg";
import careers from "@/assets/careers.png";
import { useState } from "react";

const CollapsibleLabel = ({ label }) => {
  return (
    <p
      className="text-color-primary font-medium lg:text-xl text-lg hover:underline underline-offset-2 decoration-2 border-t-2 border-color-primary pt-6 "
      style={{ width: "calc(100% + 2.5rem)" }}
    >
      {label}
    </p>
  );
};

const Careers = () => {
  const { t } = useTranslation();

  const handleScrollToForm = () => {
    const formElement = document.getElementById("contact-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const [currentIndex, setCurrentIndex] = useState(1)

  const showObj = {
    "1":['100','101'],
    "2":['102','103'],
    "3":['104','105']
  }
  return (
    <>
      <SEO title={t('careersTitle')} />
      <Layout>
        <Banner
          title={t('careersTitle')}
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        />

        <div className="page-content">
          <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
            {t('careersDescription')}
          </h2>

          <div className={`my-32`}>
            <Collapse
              defaultActiveKey={[""]}
              ghost
              size="large"
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <DownOutlined
                  className={`mt-16 ${isActive ? "rotate-180" : ""}`}
                  style={{
                    color: "var(--color-primary)",
                    fontSize: "1rem",
                  }}
                />
              )}
            />
          </div>

          <div>
            <div className="flex flex-col lg:flex-row gap-8" style={{alignItems:"center"}}>
              {/* Left side - Image */}
              <div className="lg:w-1/2">
                <div className="aspect-w-4 aspect-h-3">
                  <img 
                    src={careers} 
                    alt="Career"
                    className="w-full h-full object-cover"
                    style={{borderRadius:"8px"}}
                  />
                </div>
              </div>

              {/* Right side - Content */}
              <div className="lg:w-1/2">
                <div className="flex flex-col ">
                  {/* Toggle buttons */}
                  <div className="flex">
                    <div 
                      className="px-6 py-3 focus:outline-none focus:ring-2 focus:ring-gray-200"
                      style={{background: currentIndex===1?"rgb(222,222,222)":"#fff"}}
                      onClick={() => {
                        setCurrentIndex(1)
                      }}
                    >
                      {t('112')}
                    </div>
                    <div
                      className="px-6 py-3 focus:outline-none focus:ring-2 focus:ring-gray-200"
                      style={{background: currentIndex===2?"rgb(222,222,222)":"#fff"}}

                      onClick={() => {
                        setCurrentIndex(2)
                      }}
                    >
                      {t('113')}
                    </div>
                    <div
                      style={{background: currentIndex===3?"rgb(222,222,222)":"#fff"}}
                      className="px-6 py-3 focus:outline-none focus:ring-2 focus:ring-gray-200" 
                      onClick={() => {
                        setCurrentIndex(3)
                      }}
                    >
                      {t('114')}
                    </div>
                  </div>

                  {/* Content area */}
                  <div className="min-h-[400px]" style={{background:"rgb(222,222,222)",padding:"3rem 4rem"}}>
                    {/* <h3 className="text-2xl font-bold mb-4">{t('contentTitle')}</h3> */}
                    <p className="text-gray-600 leading-relaxed">
                      {t(showObj[currentIndex][0])}
                    </p>
                    <p className="text-gray-600 leading-relaxed">
                      {t(showObj[currentIndex][1])}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

          {/* Contact Form */}
          <div id="contact-form" className="my-16">
            <h3 className="text-color-primary font-medium text-2xl mb-4">
              {t('contactUs')}
            </h3>
            <Form layout="vertical">
              <Form.Item
                label={t('yourName')}
                name="name"
                rules={[{ required: true, message: t('nameRequired') }]}
              >
                <Input placeholder={t('yourName')} />
              </Form.Item>
              <Form.Item
                label={t('yourEmail')}
                name="email"
                rules={[
                  { required: true, message: t('emailRequired') },
                  { type: "email", message: t('emailInvalid') },
                ]}
              >
                <Input placeholder={t('yourEmail')} />
              </Form.Item>
              <Form.Item
                label={t('yourPhone')}
                name="phone"
                rules={[{ required: true, message: t('phoneRequired') }]}
              >
                <Input placeholder={t('yourPhone')} />
              </Form.Item>
              <Form.Item
                label={t('yourSubject')}
                name="subject"
                rules={[{ required: true, message: t('subjectRequired') }]}
              >
                <Input placeholder={t('yourSubject')} />
              </Form.Item>
              <Form.Item
                label={t('yourMessage')}
                name="message"
                rules={[{ required: true, message: t('messageRequired') }]}
              >
                <Input.TextArea rows={4} placeholder={t('yourMessage')} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "red" }}
                >
                  {t('submit')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Careers;
