import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import Img01 from "@/assets/img/image01.jpg";
import { useTranslation } from "react-i18next";

const BrandStoryDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Brand Story Details" />
      <Layout>
        <Banner
          title="Brand Story"
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        />

        {/* Brand Story Details Section */}
        <div className="flex gap-x-10 md:flex-row flex-col page-content pt-10 pb-16">
          <div className="flex flex-col lg:flex-row md:flex-1 lg:gap-x-20">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
              {t('53')}
              </p>
              <p className="text-lg text-gray-700 mb-8">
              {t('65')}

              </p>
              <p className="text-lg text-gray-700 mb-8">
              {t('66')}

              </p>
              <p className="text-lg text-gray-700 mb-8">
              {t('67')}

              </p>
              <p className="text-lg text-gray-700 mb-8">
              {t('68')}

              </p>
              <p className="text-lg text-gray-700 mb-8">
          {t('69')}
           
              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
              {t('10')}
              </h2>
              <p className="text-lg text-gray-700 mb-8">
              {t('58')}

              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
              {t('59')}
              </h2 >
              <p className="text-lg text-gray-700 mb-8"> 
              {t('60')}
              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
              {t('61')}
              </h2>
              <ul className="list-disc pl-4 text-lg text-gray-700 mb-8">
              <li>{t('62')}</li>
            <li>{t('63')}</li>
            <li>{t('64')}</li>
              </ul>

            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BrandStoryDetails;
