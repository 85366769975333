import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import IndexPage from "./pages/index/IndexPage";
import CapabilitiesPage from "@/pages/capabilities/CapabilitiesPage";
import ProjectsPage from "@/pages/projects/ProjectsPage";
import ProjectsDetailPage from "@/pages/projects/detail/DetailPage";
import NewsroomPage from "@/pages/newsroom/NewsroomPage";
import NewsroomDetailPage from "@/pages/newsroom/detail/DetailPage";
// import ServicesPage from "@/pages/capabilities/services/ServicesPage";
// import SectorsPage from "@/pages/capabilities/sectors/SectorsPage";
import GroupMembersPage from "@/pages/GroupMembers/GroupMembersPage";
import Careers from "@/pages/careers/Careers";
import CareersDetailPage from "@/pages/careers/detail/CareersDetailPage";
import AboutUsPage from "@/pages/about-us/AboutUsPage";
import PeoplePage from "@/pages/people/PeoplePage";
import SearchResultsPage from "@/pages/search-results/SearchResultsPage";
import BrandStoryDetails from "@/pages/about-us/BrandStoryDetails";
import ContactUs from "@/components/contact-us/ContactUs";
import GetInTouchForm from "@/components/get-in-touch/GetInTouchForm";
import Projectsa from "@/pages/careers/Projects";
import OurPartners from "@/pages/about-us/ourPartners";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  // const pathname = window.location.pathname

  return (
    <Router>
      <Routes>
        <Route exact path={`/`} element={<IndexPage />} />

        {/* <Route exact path={`/capabilities`} element={<CapabilitiesPage />} />
        <Route
          path={`/capabilities/*`}
          element={<Navigate to="/capabilities" />}
        />
        <Route
          exact
          path="/capabilities/services/:id"
          element={<ServicesPage />}
        />
        <Route
          exact
          path="/capabilities/sectors/:id"
          element={<SectorsPage />}
        /> */}

        <Route exact path="/projects" element={<ProjectsPage />} />
        <Route exact path="/projects/*" element={<ProjectsDetailPage />} />

        <Route exact path="/newsroom" element={<NewsroomPage />} />
        <Route path="/newsroom/:id" element={<NewsroomDetailPage />} />

        <Route exact path="/group-members" element={<GroupMembersPage />} />

        <Route exact path="/careers" element={<Careers />} />

        <Route exact path="/projects" element={<Projectsa/>} />

        <Route exact path="/careers/*" element={<CareersDetailPage />} />

        <Route exact path="/about-us/*" element={<AboutUsPage />} />

        <Route exact path="/people/*" element={<PeoplePage />} />

        <Route exact path="/search-results/*" element={<SearchResultsPage />} />

        <Route exact path="/brandStory" element={<BrandStoryDetails />} />

        <Route exact path="/ourPartners" element={<OurPartners />} />


        <Route path="/contact-us" element={<ContactUs />} />  {/* ContactUs 组件 */}
        <Route path="/get-in-touch-form" element={<GetInTouchForm />} />  {/* GetInTouchForm 组件 */}
      </Routes>
    </Router>
  );
}

export default App;
