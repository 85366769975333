import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import { useTranslation } from "react-i18next";
const OurPartners = () => {
    const fontStyle = {
        color: "#fff"
    }
    const list1 = [
        '../images/partner logo/city council/01.png',
        '../images/partner logo/city council/02.png',
        '../images/partner logo/city council/03.png',
        '../images/partner logo/city council/04.png',
        '../images/partner logo/city council/05.png',
        '../images/partner logo/city council/06.png',
        '../images/partner logo/city council/07.png',
        '../images/partner logo/city council/08.png',
        '../images/partner logo/city council/09.png',
        '../images/partner logo/city council/10.png',
        '../images/partner logo/city council/11.png',
        '../images/partner logo/city council/12.png',
        '../images/partner logo/city council/13.png',
        '../images/partner logo/city council/14.png',
        '../images/partner logo/city council/15.png',
        '../images/partner logo/city council/16.png',
        '../images/partner logo/city council/17.png',
        '../images/partner logo/city council/18.png',
    ]
    const list2 = [
        '../images/partner logo/SA housing Authority/4.png',
        '../images/partner logo/SA housing Authority/1.png',
        '../images/partner logo/SA housing Authority/2.png',
        '../images/partner logo/SA housing Authority/3.png',
    ]
    const list3 = [
        '../images/partner logo/Real Estate Agents/1.png',
        '../images/partner logo/Real Estate Agents/2.png',
        '../images/partner logo/Real Estate Agents/4.png',
        '../images/partner logo/Real Estate Agents/3.png',
        '../images/partner logo/Real Estate Agents/7.png',

        '../images/partner logo/Real Estate Agents/5.png',
        '../images/partner logo/Real Estate Agents/6.png',
    ]

    const list4 = [
        '../images/partner logo/Data Support/02.png',
        '../images/partner logo/Data Support/01.png',
    ]

    const list5 = [
        '../images/partner logo/legal support/2.png',
        '../images/partner logo/legal support/3.png',
        '../images/partner logo/legal support/4.png',
        '../images/partner logo/legal support/1.png',

    ]

    const list6 = [
        '../images/partner logo/finance loan/13.png',
        '../images/partner logo/finance loan/14.png',
        '../images/partner logo/finance loan/15.png',
    ]
    const list7 = [
        '../images/partner logo/construction and planning/01.png',
        '../images/partner logo/construction and planning/02.png',
        '../images/partner logo/construction and planning/03.png',
        '../images/partner logo/construction and planning/04.png',
        '../images/partner logo/construction and planning/09.png',
        '../images/partner logo/construction and planning/08.png',
        '../images/partner logo/construction and planning/07.png',
    ]

   
    const Components = ({ title, list }) => {
        return <div className="page-content pt-10 pb-16">
            <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem" }}>{title}</h2>
            <div style={{ backgroundColor: "#fff", width: "100%", display: "grid", gridTemplateColumns: " repeat(4, 1fr)", flexWrap: "wrap", alignItems: "center", rowGap: "40px", padding: "2rem", borderRadius: "40px", columnGap: "20px" }}>
                {list && list.map(item => {
                    return <div><img style={{ width: '100%', height: '100%', maxHeight: "250px", maxWidth: "250px", objectFit: "contain" }} src={item}></img></div>
                })}
            </div>
        </div>
    }
    const { t, i18n } = useTranslation();
    return (
        <>
            <SEO title="Our Partners" />
            <Layout>
                <div style={{ backgroundColor: '#293766' }}>
                    <div id="stateGov" className="page-content pt-10 pb-16">
                        <h3 style={{...fontStyle,fontSize:"3rem"}} >{t('partnersStateGov')}</h3>
                        <div style={{ ...fontStyle, fontSize: "4rem" }} >{t('partnersState')}</div>
                        <div style={{ ...fontStyle, fontSize: "4rem", marginTop: "-1rem" }} >{t('partnersGovernment')}</div>
                        <img style={{ backgroundColor: "#fff", borderRadius: "40px", margin: "0 auto", padding:"2rem 3rem", display:"flex",justifyContent:"center",alignItems:"center"}} src='../images/partner logo/federal government/logo_disr.png'></img>

                        <div >
                        </div>
                    </div>
                    <div id="cityCouncil">
                        <Components title={t('partnersCityCouncil')} list={list1}></Components>
                    </div>
                    <div id="saHousing" className="page-content pt-10 pb-16">
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem" }}>{t('partnersSAHousing')}</h2>
                        <div style={{ backgroundColor: "#fff", width: "100%", display: "grid", gridTemplateColumns: " repeat(2, 1fr)", flexWrap: "wrap", alignItems: "center", rowGap: "40px", padding: "4rem 2rem", borderRadius: "40px", columnGap: "20px" }}>
                            {list2 && list2.map(item => {
                                return <div><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>

                    <div id="realEstate" className="page-content pt-10 pb-16">
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem" }}>{t('partnersOurPartners')}</h2>
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem",textAlign:"center" }}>{t('partnersRealEstate')}</h2>
                        
                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex",justifyContent:"center",flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list3 && list3.map(item => {
                                return <div style={{flex:'23%',maxWidth:"20%"}}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>

                    <div id="dataSupport" className="page-content pt-10 pb-16">
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem",textAlign:"center" }}>{t('partnersDataSupport')}</h2>
                        
                        <div style={{ backgroundColor: "#fff", width: "50%",margin:"0 auto", display: "flex",justifyContent:"space-around",flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "0rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list4 && list4.map(item => {
                                return <div style={{flex:'23%',maxWidth:"50%"}}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>


                    <div id="legalSupport" className="page-content pt-10 pb-16">
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem",textAlign:"center" }}>{t('partnersLegalSupport')}</h2>
                        
                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex",justifyContent:"center",flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list5 && list5.map(item => {
                                return <div style={{flex:'23%',maxWidth:"20%"}}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>

                    <div id="financeLoan" className="page-content pt-10 pb-16">
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem",textAlign:"center" }}>{t('partnersFinanceLoan')}</h2>
                        
                        <div style={{ backgroundColor: "#fff", width: "70%",margin:"0 auto", display: "flex",justifyContent:"space-around",flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list6 && list6.map(item => {
                                return <div style={{flex:'23%',maxWidth:"20%"}}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>
 

                    <div className="page-content pt-10 pb-16">
                        <a id="construction"></a>
                        <h2 style={{ ...fontStyle, fontSize: "4rem", paddingBottom: "1.5rem",textAlign:"center" }}>{t('partnersConstruction')}</h2>
                        
                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex",justifyContent:"center",flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list7 && list7.map(item => {
                                return <div style={{flex:'23%',maxWidth:"20%"}}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default OurPartners;
 