import React, { useState, useEffect } from "react";
import SearchHeadDropdown from "./SearchHeadDropdown";
import SortBy from "./SortBy";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const SearchHead = () => {
  const [filterData, setFilterData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // 控制搜索框的可见性
  const [searchTerm, setSearchTerm] = useState(""); // 用于存储搜索内容

  useEffect(() => {
    const handleDocumentResize = () => {
      setIsOpen(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleDocumentResize);
    handleDocumentResize();
    return () => {
      window.removeEventListener("resize", handleDocumentResize);
    };
  }, []);
  const { t } = useTranslation();

  const changeSearch = (data) => {
    console.log("changeSearch=", data);
    setFilterData(data);
  };

  const changeSort = (data) => {
    console.log("changeSort=", data);
  };

  // 处理搜索输入变化
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // 在这里可以添加逻辑来根据搜索项过滤数据
  };

  return (
    <>
      <div className="flex justify-between lg:items-center lg:flex-row flex-col w-full bg-white">
        <div
          className="flex items-center justify-between lg:hidden md:px-8 px-6 py-4 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="uppercase">Filter By</span>
          <DownOutlined
            className={`transition-all duration-300 ease-in-out ml-4 ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </div>

        {isOpen && (
          <>
            <div className="md:px-8 px-6 bg-slate-100 lg:bg-transparent">
              <div className="flex gap-x-8 lg:flex-row flex-col">
                <div className="border-b border-gray-300 lg:border-0">
                  <SearchHeadDropdown
                    checkList={filterData}
                    label={t('32')}
                    optionsList={[
                      { id: 1, label: "Completed", count: 71 },
                      { id: 2, label: "In Development", count: 9 },
                      { id: 3, label: "Coming Soon", count: 1 },
                    ]}
                    onChangeSearch={changeSearch}
                  />
                </div>

                <div className="border-b border-gray-300 lg:border-0">
                  <SearchHeadDropdown
                    checkList={filterData}
                    label={t('33')}
                    optionsList={[
                      { id: 11, label: t('35'), count: 71 },
                      { id: 2, label: t('34'), count: 9 },
                      { id: 3, label: t('35'), count: 1 },
                      // { id: 4, label: "Mixed Use", count: 1 },
                      // { id: 5, label: "Healthcare", count: 1 },
                    ]}
                    onChangeSearch={changeSearch}
                  />
                </div>

                <div
                  className={`text-sm p-4 transition-colors duration-300 ease-in-out ${
                    filterData.length > 0
                      ? "cursor-pointer hover:bg-slate-100"
                      : "cursor-not-allowed opacity-40"
                  }`}
                  onClick={() => changeSearch([])}
                >
                  <span className="uppercase">Clear Filter</span>
                </div>
              </div>
            </div>

            <div className="md:px-8 px-6 bg-slate-600 lg:bg-transparent">
              <div className="flex lg:items-center gap-x-4 lg:flex-row flex-col">
                {/* 搜索图标 */}
                <div className="relative mb-4">
                  <SearchOutlined
                    onClick={() => setIsSearchVisible(!isSearchVisible)} // 点击放大镜切换输入框的显示
                    className="text-white cursor-pointer"
                    style={{ fontSize: "24px" }} // 放大镜图标大小
                  />
                  {isSearchVisible && ( // 根据状态显示输入框
                    <Input
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="rounded-l-lg absolute left-0 top-full mt-1"
                      style={{ width: "200px" }} // 输入框宽度
                    />
                  )}
                </div>

                <span className="text-sm lg:opacity-50 py-4 lg:py-0 border-b border-white lg:border-0 text-white lg:text-gray-950 uppercase">
                  {t('34')}
                </span>
                <SortBy
                  optionsList={[
                    { id: 1, label: "State Government" },
                    { id: 2, label: t('36') },
                    { id: 3, label: "OthersCareers" },
                  ]}
                  onChangeSort={changeSort}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchHead;
