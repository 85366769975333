import { useParams } from 'react-router-dom';
import newsList from '../news.json';
import { useTranslation ,Trans} from "react-i18next";
import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import ContactUsItemTwo from "@/components/contact-us-list/ContactUsItemTwo";
import news2 from "@/assets/img/guide/news2.png";
import news3 from "@/assets/img/guide/DSC07158.jpg";

import { XOutlined } from "@ant-design/icons";
import Img01 from "@/assets/img/image01.jpg";
import image from "@/assets/img/guide/image.png";
import image2 from "@/assets/img/guide/image2.png";
import image3 from "@/assets/img/guide/image3.png";
import InSvg from "@/assets/svg/InSvg";
import FacebookSvg from "@/assets/svg/FacebookSvg";
import MainlSvg from "@/assets/svg/MainlSvg";

import Icon from "@ant-design/icons";

const DetailPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  
  // 根据URL参数找到对应的新闻
  const newsItem = newsList.find(item => item.id === id);
  
  // 如果没找到对应的新闻，可以显示404或重定向
  if (!newsItem) {
    return <div>News not found</div>;
  }

  const imageMap = {
    "image": image,
    "image2": image2,
    "image3": image3,
    "news2": news2,
    "news3": news3

    // ... 其他图片映射
  };

  return (
    <>
      <SEO title={t(newsItem.title)}></SEO>
      <Layout>
        <Banner
          subTitle={newsItem.subTitle}
          titleNode={
            <p className="font-lab-antiqua text-3xl lg:text-4xl xl:text-5xl w-full md:w-auto mt-3">
              {t(newsItem.title)}
            </p>
          }
          bgColor="#D1D7E0"
          description={newsItem.date}
          textColor="#0A1E41"
          img={imageMap[newsItem.imgUrl]}
          bottomElement={
            <ul className="mt-6 flex gap-x-4 ">
              <li>
                <a href="/">
                  <Icon
                    component={FacebookSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "20px", height: "20px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <XOutlined
                    className="transition-colors duration-200 ease-in-out"
                    style={{ fontSize: "18px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <Icon
                    component={InSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "24px", height: "24px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <Icon
                    component={MainlSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "22px", height: "22px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
            </ul>
          }
        />

        <div className="page-content">
          <div className="mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w flex md:block flex-col">
            {/* Media Contact */}
            {/* {newsItem.mediaContact && (
              <div className="md:float-right md:ml-16 order-4">
                <div className="w-72 py-10 px-4 bg-[--color-secondary-light]">
                  <p className="text-xs mb-2 uppercase">Media Contact</p>
                  <ContactUsItemTwo contact={newsItem.mediaContact} />
                </div>
              </div>
            )} */}
            
            {/* News Content */}
            <div className="text-lg leading-8">
              {newsItem.newsContent.map((paragraph, index) => (
                <p key={index} >
                  <Trans i18nKey={paragraph} />
                </p>
              ))}

              {/* Render additional images from imgUrlList */}
              {newsItem.imgUrlList && newsItem.imgUrlList.map((imgKey, index) => (
                <img
                  key={index}
                  src={imageMap[imgKey]}
                  alt={`Additional image ${index + 1}`}
                  className="my-4 w-full h-auto"
                />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DetailPage;
