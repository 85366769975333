import React from "react";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Carousel from "@/components/carousel/Carousel";
import CornerMarkList from "@/components/corner-mark-list/CornerMarkList";
import CardImgTextX from "@/components/card/card-img-text-x/CardImgTextX";
import CardInImg from "@/components/card/card-in-img/CardInImg";
import NewsModule from "@/components/news-module/NewsModule";
import ContactUs from "@/components/contact-us/ContactUs";
import GroupMembers from "@/components/brand/groupMembers";
import HeadCarousel from "@/components/head-carousel/HeadCarousel";
import { RightOutlined } from "@ant-design/icons";
import Img01 from "@/assets/img/image01.jpg";
import Img03 from "@/assets/img/img03.jpg";
import Img04 from "@/assets/img/img04.jpg";


import NewsroomPageF from "../newsroom/NewsroomPageF";
import navLinkGroup from "@/data/header-link/linkGroup.json";
import { useTranslation } from "react-i18next";
const IndexPage = () => {
  const sectorsLinkList = (
    navLinkGroup.CapabilitiesLinkGroup.filter(
      (item) => item.title === "Sectors"
    )[0].list || []
  ).filter((v) => v.name !== "Data Centers");
  const { t, i18n } = useTranslation();
  const companies = [
    {
      name: "Cyberate Technologies",
      img: "/images/brand/Cyberate.jpg",
      link: "https://www.cyberate.com.au/",
      description: t('19'),
      content: t('cyberateTechnologiesDesc'),
      introduction: t('cyberateTechnologies'),
    },
    {
      name: "Cyberate Investment",
      img: "/images/brand/Investment.jpg",
      link: "https://www.cyberate.investments/",
      description: t('20'),
      content: t('cyberateInvestmentsDesc'),
      introduction: t('cyberateInvestments'),
    },
    {
      name: "Cyberate Finance",
      img: "/images/brand/Finance.jpg",
      link: "https://cyberate.finance/",
      description: t('developmentLoan'),
      content: t('cyberateFinanceDesc'),
      introduction: t('cyberateFinance'),
    },
    {
      name: "Cyberate Project Management",
      img: "/images/brand/Project management.jpg",
      link: "https://www.cyberate.com.au/",
      content: t('cyberatePMDesc'),
      description: t('22'),
      introduction: t('cyberateProjectManagement'),
    },
    {
      name: "Cyberidge Construction",
      img: "/images/brand/Cyberidge.jpg",
      content: t('cyberidgeConstructionDesc'),
      link: "https://www.cyberidge.com.au/",
      description: t('28'),
      introduction: t('cyberidgeConstruction')
    },
  ];
  return (
    <>
      <SEO title="DDDI Group"></SEO>
      <Layout>
        <Carousel></Carousel>

        <div className="w-full bg-white">
          <div className={`page-content`}>
            {/* Module A */}
            <style>
              {`
                [id] {
                  scroll-margin-top: 100px;
                }
              `}
            </style>
            <div id="glance">
              <CornerMarkList headerText={t('12')}></CornerMarkList>
            </div>
            {/* Module B */}
            {/* <div className={`flex lg:flex-nowrap flex-wrap gap-5 mb-10`}>
              <div className={`w-full lg:max-w-md`}>
                <h3 className="font-lab-antiqua text-5xl font-bold py-8">
                  What We Do
                </h3>
                <div className={`text-md`}>
                  DDDI Group is a leading property development service group
                  that leverages big data to provide comprehensive, one-stop
                  services across technologies, finance services, and
                  construction services.
                </div>
              </div>
              <div className={`w-full`}>
                <ul className="mt-4 grid lg:grid-cols-2 md:grid-cols-2 gap-x-10">
                  {sectorsLinkList
                    .sort((a, b) => a.sort - b.sort)
                    .map((link, index) => (
                      <li
                        key={index}
                        className="flex justify-between border-b border-gray-300 hover:text-sky-400"
                      >

                        {link.name}

                        <RightOutlined
                          style={{
                            color: "#0a1e41",
                            fontSize: "0.7rem",
                          }}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div> */}


            <div
              id="company"
              style={{
                backgroundColor: "#ffffff", // Matches the page's white background
                padding: "60px 20px",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "40px",
                  fontSize: "2.5em",
                  fontWeight: "bold",
                  color: "#0a1e41", // Text color matches your page
                }}
              >
                {t('proudGroupMembers')}
              </h2>
            </div>



          </div>

          <HeadCarousel companies={companies} />

          {/* <GroupMembers /> */}
          <br />
          <br />
          <div className={`page-content`}>
            {/* <div className="row" style={{ background: "#fff", rowGap: "8rem" }}>
              {
                companies.map((item, index) => {
                  if (index % 2 === 1 || window.screen.width < '600') {
                    return <>
                      <div id={item.name.replace(/\s+/g, '-').toLowerCase()} className="col-lg-6 col-md-6 col-sm-6" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                        <div>{item.content}</div>
                        <div style={{ backgroundColor: 'var(--color-footer-bg)', width: '100px', color: "#fff", display: "flex", marginTop: "40px", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: "6px" }}>{t('learnMore')}</div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6" >
                        <img style={{ width: "100%", height: "100%" }} src={item.img}></img>
                      </div>
                    </>
                  } else {
                    return <>
                      <div id={item.name.replace(/\s+/g, '-').toLowerCase()} className="col-lg-6 col-md-6 col-sm-6">
                        <img style={{ width: "100%", height: "100%" }} src={item.img}></img>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }} >
                        <div>{item.content}</div>
                        <div style={{ backgroundColor: 'var(--color-footer-bg)', width: '100px', color: "#fff", display: "flex", marginTop: "40px", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: "6px" }}>{t('learnMore')}</div>
                      </div>
                    </>
                  }
                })
              }
            </div> */}




            {/* <div className="my-20">
              <CardInImg
                images={[Img01, Img03, Img04]}
                subTitle="Featured Projects"
                title="Projects"
                description="DDDI Group utilises data and technology to deliver
                precise investment insights, empowering investors to
                achieve lasting wealth growth."
                linkLeft="https://cyberate.projectmanagement/"
                linkLeftText="Learn More"
                linkRight="https://cyberate.projectmanagement/"
                linkRightText="All Projects"
              />
            </div> */}

            <div className="mt-20" id="newsroom">
              <NewsModule
                headText={t('newsRoom')}
                bigItemTitle={false}
                rightLink="/newsroom"
                rightLinkText={t("learnMore")}
              ></NewsModule>
            </div>

            {/* <div className="mt-20">
              <NewsModule
                headText="Projects"
                bigItemTitle={false}
                rightLink="https://www.cyberate.com.au/"
                isProject={true}
                rightLinkText="Learn More"
                contentText="DDDI Group utilises data and technology to deliver precise investment
insights, empowering clients to achieve lasting wealth growth. By providing
expert guidance on project selection and leveraging big data analytics to
align projects with investment objectives and risk appetite, the portfolio offers
comprehensive real estate assistance. Through sophisticated valuation
models and negotiation expertise, clients obtain favorable purchase terms.
The design process is guided by market trends while ensuring regulatory
compliance. End-to-end project management services ensure timely and high
budget project completion. Construction consulting optimized technology
and materials, and achieved excellent results. Strategic investment advice
maximizes returns, minimizes risks and aligns with financial objectives.
Personalized Home Building Consulting supports clients to customize
buildings that reflect their vision and standards."
              ></NewsModule>
            </div> */}
          </div>

          {/* <NewsroomPageF /> */}

          <ContactUs></ContactUs>
        </div>
        <div className="lg:h-28 md:h-16 h-8 w-full"></div>
      </Layout>
    </>
  );
};

export default IndexPage;
