import { useState, useRef, useEffect } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import styles from "./HeadCarousel.module.scss";
import { useTranslation } from "react-i18next";

const HeadCarousel = ({companies}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();

  // const companies = [
  //   {
  //     name: "Cyberate Technologies",
  //     img: "/images/brand/Cyberate.jpg",
  //     link: "https://www.cyberate.com.au/",
  //     description: t('19'),
  //     introduction:
  //      t('cyberateTechnologies'),
  //   },
  //   {
  //     name: "Cyberate Investment",
  //     img: "/images/brand/Investment.jpg",
  //     link: "https://www.cyberate.investments/",
  //     description: t('20'),
  //     introduction:
  //     t('cyberateInvestments'),
  //   },
  //   {
  //     name: "Cyberate Finance",
  //     img: "/images/brand/Finance.jpg",
  //     link: "https://cyberate.finance/",
  //     description: t('developmentLoan'),
  //     introduction:
  //     t('cyberateFinance'),

  //   },
  //   {
  //     name: "Cyberate Project Management",
  //     img: "/images/brand/Project management.jpg",
  //     link: "https://www.cyberate.com.au/",
  //     description: t('22'),
  //     introduction:
  //     t('cyberateProjectManagement'),

  //   },
  //   {
  //     name: "Cyberidge Construction",
  //     img: "/images/brand/Cyberidge.jpg",
  //     link: "https://www.cyberidge.com.au/",
  //     description: t('28'),
  //     introduction:
  //      t('cyberidgeConstruction')
  //   },
  // ];

  const dataListRef = useRef(null);

  // 自动滑动
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === companies?.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 每3秒切换一次

    return () => clearInterval(interval); // 清除定时器
  }, [companies?.length]);

  useEffect(() => {
    const gallery = dataListRef.current;
    if (gallery) {
      const itemWidth = window.innerWidth < 1024 ? window.innerWidth : 400;
      const leftPosition = currentIndex * itemWidth;

      gallery.scrollTo({
        left: leftPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? companies.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === companies.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="bg-[#333d4d] relative">
      {/* 左右箭头 */}
      <div className={`${styles["btns"]}`}>
        <ArrowLeftOutlined
          className={`${styles["icon"]}`}
          onClick={handlePrev}
        />
        <ArrowRightOutlined
          className={`${styles["icon"]}`}
          onClick={handleNext}
        />
      </div>

      {/* 图片滚动列表 */}
      <ul
        ref={dataListRef}
        className={`flex items-stretch overflow-y-hidden overflow-x-scroll pt-12 relative lg:static page-content ${styles["carousel-items"]}`}
      >
        {companies && companies.map((company, index) => (
          <li
            className={`flex-none relative text-white lg:mt-16 lg:pb-8 py-10 lg:px-8 md:px-8 pl-10 ${styles["carousel-item"]}`}
            key={index}
          >
            <div>
              <p className=" lg:text-sm text-xs pr-10 md:pr-0" style={{height:'3rem'}}>
                {company.description}
              </p>
              <div className="flex lg:flex-col md:flex-row flex-col">
                <a
                  href={company.link}
                  className={`lg:w-auto md:w-[55%] ${styles["cover"]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 {window.screen.width>=800 ? <div style={{backgroundColor:"#fff",display:'flex',alignItems:"center",justifyContent:"center"}} >
                  <img
                    src={company.img}
                    alt={company.name}
                    className="object-contain w-[230px] h-[230px]"
                  />
                  </div>:<img
                    src={company.img}
                    alt={company.name}
                    className="object-contain w-[230px] h-[230px]"
                  />}

                </a>

                <div className=" md:mt-0 overflow-y-hidden md:w-5/12 md:pl-10 lg:w-auto mt-10">
                  <h3 className="font-lab-antiqua lg:text-3xl md:text-2xl text-xl pr-10 md:pr-0 hover:underline underline-offset-4 decoration-2">
                    <a
                      href={company.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration:"none",color:"#fff"}}
                    >
                      {company.name}
                    </a>
                  </h3>
                  <p
                    className={`mt-10 pr-10 md:pr-0 w-full overflow-hidden lg:h-0 lg:text-base lg:text-white text-gray-300 text-xs leading-5 text-ellipsis ${styles["description"]}`}
                  >
                    {company.introduction}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeadCarousel;
