import { useState } from "react";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CardImg from "@/components/card/card-img/CardImg";
import SearchHead from "@/components/search-head/SearchHead";

import Img01 from "@/assets/img/image01.jpg";
import ImgB from "@/assets/img/b.jpg";

import NewsroomPageA from "../newsroom/NewsroomPageA";

import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

import Link from "antd/es/typography/Link";
import ContactUs from "@/components/contact-us/ContactUs";

const ProjectsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation();

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <>
      <SEO title="Projects"></SEO>
      <Layout>
        <Banner
          title="Projects"
          bgColor="#F2EDE8"
          description={t('30')}
          textColor="#0A1E41"
          img={ImgB}
        ></Banner>

    

        <div className="flex gap-x-10 md:flex-row flex-col page-content pt-10 pb-16">
          <div className="flex flex-col lg:flex-row md:flex-1 lg:gap-x-20">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
              {t('53')}
              </p>
      
           {/* <NewsroomPageA/> */}

           <div className="pt-8 mt-10">
            <ul className="flex lg:gap-x-28 gap-y-14 flex-wrap lg:flex-nowrap pb-8">
              {[1, 2, 3].map((item, index) => (
                <li key={index} className="lg:flex-1 w-full">
                  <CardImg
                    img={Img01}
                    subTitle="Washington, DC"
                    title={"Medstar Georgetown University Hospital"}
                    shareIcon={true}
                    link={"/projects/medstar-georgetown-university-hospital"}
                    linkText={t('readMore')}
                  />
                </li>
              ))}
            </ul>

            <div className="flex justify-end">
              <Link href="/Project">
                <a className="mt-4 px-6 py-2 inline-block bg-blue-500 hover:bg-blue-700 rounded-lg" style={{ color: "black" }}>
                  {t('learnMore')}
                </a>
              </Link>
            </div>



          </div>



            </div>
          </div>
        </div>
        <ContactUs/>
      </Layout>
    </>
  );
};

export default ProjectsPage;
