import React from 'react';

const teamData = [
    { image: 'Jax', name: 'Jax', designation: 'General Manager' },
    { image: 'Viv', name: 'Viv', designation: 'Strategy Manager' },
    { image: 'Ashley', name: 'Ashley', designation: 'Product Manager' },
    { image: 'Patrick', name: 'Junzhe Diao', designation: 'Technical Manager' },
    { image: 'Lin', name: 'Lin', designation: 'Marketing Manager' },
    { image: 'Lorenzo', name: 'Xinchao Lin', designation: 'Architect' },
    { image: 'Angelia', name: 'Angelia', designation: 'Administration' },
    { image: 'Irene', name: 'Irene', designation: 'Statistician' },
    { image: 'Rosy', name: 'Rosy', designation: 'Accountant' },
    { image: 'Jessie', name: 'Jessie', designation: 'Data Analyst' },
    { image: 'Candice', name: 'Candice', designation: 'Data Analyst' },
    { image: 'Jason', name: 'Jason', designation: 'Software Engineer' },
    { image: 'Winston', name: 'Winston', designation: 'Software Engineer' },
    { image: 'Eugenia', name: 'Eugenia', designation: 'Software Engineer' },
    { image: 'Mingjun', name: 'Mingjun', designation: 'Software Engineer' },
    { image: 'yuchen', name: 'Yuchen', designation: 'Software Engineer' },
];

const rowsLayout = [2, 3, 4, 3, 4]; // 定义每行的图片数量

const TeamThree = () => {
    let startIndex = 0;

    return (
        <div className="container">
            {rowsLayout.map((itemsPerRow, rowIndex) => {
                const rowItems = teamData.slice(startIndex, startIndex + itemsPerRow);
                startIndex += itemsPerRow;

                return (
                    <div
                        className="row mb-4 justify-content-center"
                        key={`row-${rowIndex}`}
                    >
                        {rowItems.map((member, memberIndex) => (
                            <div
                                className={`col-lg-3 col-md-4 col-sm-6`} // 设置固定列宽，保持一致
                                key={memberIndex}
                            >
                                <div className="team-card text-center">
                                    <img
                                        src={`/images/team/${member.image}.png`}
                                        alt={member.name}
                                        className="img-fluid mb-3"
                                        style={{
                                        
                                            objectFit: 'cover', // 保持内容比例裁剪
                                            borderRadius: '10px', // 圆角效果
                                        }}
                                    />
                                    <h3 className="font-weight-bold">{member.name}</h3>
                                    <p className="text-muted">{member.designation}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default TeamThree;
